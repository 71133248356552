import React from "react";
import { FaInstagramSquare } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { CiLinkedin } from "react-icons/ci";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaGithubSquare } from "react-icons/fa";
import { CgMail } from "react-icons/cg";

function Contact() {
  return (
    <>
      <div className="container contact" id="contact">
        <h1>CONTACT ME</h1>
        <div className="contact-icon" data-aos="zoom-in-up" data-aos-duration="2000">
          <a href="https://www.instagram.com/vishal_rajput_2_0/profilecard/?igsh=a203Y2Z1Z3kxcGF0" target="_blank" className="items">
            <FaInstagramSquare className="icon" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100024476616887" target="_blank" className="items">
            <CiFacebook className="icon" />
          </a>
          <a href="https://www.linkedin.com/in/vishal-singh-niet/" target="_blank" className="items">
            <CiLinkedin className="icon" />
          </a>
          <a href="" target="_blank" className="items">
            <FaSquareXTwitter className="icon" />
          </a>
          <a href="https://github.com/VishalNOV29" target="_blank" className="items">
            <FaGithubSquare className="icon" />
          </a>
          <a href="mailto:vishalsingh841209@gmail.com" target="_blank" className="items">
            <CgMail className="icon" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Contact;
