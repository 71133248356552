import React from "react";

function Navbar() {
  return (
    <>
      <div
        className="container nav_bar"
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className="left nav_items">
          <a href="#home" className="right" style={{ fontSize: "30px" }}>
            𝒱𝒾𝓈𝒽𝒶𝓁 𝒮𝒾𝓃𝑔𝒽
          </a>
        </div>
        <div className="right">
          <a href="#home" className="nav_items">
            Home
          </a>
          <a href="#experience" className="nav_items">
            Experience
          </a>
          <a href="#skills" className="nav_items">
            Skills
          </a>
          <a href="#project" className="nav_items">
            Project
          </a>
          <a href="#contact" className="nav_items">
            Contact
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar;
