import React, { useEffect, useRef } from "react";
import resume from "../pdf/resume.pdf";
import Typed from "typed.js";

function Home() {
  const typedRef = useRef(null);
  useEffect(() => {
    const options = {
      strings: [
        "Welcome to my profile",
        "My name is Vishal Kumar Singh",
        "I'm a Full Stack Developer (MERN Stack)"
      ],
      typeSpeed: 60,
      backSpeed: 60,
      loop: true,
    };

    const typed = new Typed(typedRef.current, options);
    return () => {
      typed.destroy();
    };
  }, []);

  return (
    <div className="container home" id="home">
      <div className="left" data-aos="zoom-in-up" data-aos-duration="2000">
        <h1 ref={typedRef} aria-live="polite"></h1>
        <a
          href={resume}
          download="resume.pdf" // Corrected the download attribute
          className="btn btn-outline-warning"
        >
          Download Resume
        </a>
      </div>
    </div>
  );
}

export default Home;
