import React from "react";

import certificationsData from "./data/certification.json"; // Import the JSON file directly

const Certification = () => {
  return (
    <div className="certifications-section" data-aos="zoom-in-up" data-aos-duration="2000">
      <h2>{certificationsData.title}</h2>
      <div className="certifications-list">
        {certificationsData.certifications.map((cert, index) => (
          <div className="certification-item" key={index}>
            <h3>
              {cert.name}{" "}
              <a
                className="cert-link"
                href={cert.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                (View Certificate)
              </a>
            </h3>
            <p className="institution-year">
              <strong>{cert.institution}</strong>, {cert.year}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Certification;
