import React from "react";
import skills from "./data/skills.json";

function Skills() {
  return (
    <>
      <div className="container skills" id="skills" data-aos="zoom-in-up" data-aos-duration="2000">
        <h1>SKILLS</h1>
        <div className="items">
          {skills.map((data) => (
            <div
              className="item"
              key={data.id}
              data-aos="flip-left"
              data-aos-duration="2000"
            >
              <img src={`/assests/${data.imageSrc}`} alt="" />
              <h3>{data.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
