import React, { useState } from "react";
import project from "./data/projects.json";

function Project() {
  const [selectedProject, setSelectedProject] = useState(null);

  const closeModal = () => setSelectedProject(null);

  return (
    <>
      <div className="container projects my-3" id="project" data-aos="zoom-in-up" data-aos-duration="2000">
        <h1>PROJECT</h1>
        <div className="row d-flex justify-content-center align-item-center">
          {project.map((data, index) => (
            <div
              key={index}
              className="project-card my-3 col-sm-6 col-md-4 col-lg-3 mx-3"
              data-aos="flip-right"
              data-aos-duration="2000"
            >
              <div className="card  text-light">
                <div className="img d-flex justify-content-center align-item-center p-3">
                  <img src={data.imageSrc} className="card-img-top" alt="..." />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title">{data.title}</h5>

                  <button
                    className="btn btn-info mx-3"
                    onClick={() => {
                      console.log("Selected Project:", data); // Debug log
                      setSelectedProject(data);
                    }}
                  >
                    Know More
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedProject && (
        <div className="modal-backdrop" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-body">
              <p>
                <h4>Description:</h4> {selectedProject.description}
              </p>
              <p>
                <h4>Key Features:</h4>
                <ul>
                  {selectedProject.keyfeatures.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              </p>
              <h4>Technology Used:</h4>
              <table className="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Technology</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(selectedProject.techStack).map(
                    ([key, value], idx) => (
                      <tr key={idx}>
                        <td>{key.charAt(0).toUpperCase() + key.slice(1)}</td>
                        <td>{value}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <a
                href={selectedProject.demo}
                className="btn btn-primary mx-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Demo
              </a>
              <a
                href={selectedProject.source}
                className="btn btn-warning mx-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                Code
              </a>
            </div>
            <button
              className="btn btn-danger mt-3 close-btn"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Project;
